import React, { useState, useEffect } from "react";
import { FaCheck, FaPlane } from "react-icons/fa";
import { RiTruckFill, RiShip2Fill } from "react-icons/ri";
import { AiTwotoneFile } from "react-icons/ai";
import { BsFlagFill } from "react-icons/bs";
import { FaDollarSign } from "react-icons/fa";
import { VscCircleFilled } from "react-icons/vsc";

import Collapse from "../../../components/Collapse";
import * as S from "../styles";

const ProcessDetail = ({ openCollapse, setOpenCollapse, process }) => {
    let [percent, setPercent] = useState(0);

    useEffect(() => {
        if (process.currentStageModality === "Pré embarque") {
            setPercent(10);
        } else if (process.currentStageModality === "Trânsito") {
            setPercent(30);
        } else if (process.currentStageModality === "Desembaraço") {
            setPercent(50);
        } else if (process.currentStageModality === "Faturamento") {
            setPercent(70);
        } else if (process.currentStageModality === "Transporte") {
            setPercent(90);
        } else if (process.currentStageModality === "Entrega") {
            setPercent(100);
        }
    }, []);

    const color = (isActive, origin) => {
        if (origin) {
            return isActive ? "rosa_oficial" : "C4";
        } else {
            return isActive ? "roxo_oficial" : "C4";
        }
    };

    const colorIcon = (isActive, origin) => {
        if (origin) {
            return isActive ? "#E52359" : "#A7A7A7";
        } else {
            return isActive ? "#422C76" : "#A7A7A7";
        }
    };

    const icon = (process, isActive) => {
        if (process.transportModality === "Marítimo") {
            return (
                <S.Icon colorStep={colorIcon(isActive, process.returnOrigin)}>
                    <RiShip2Fill />
                </S.Icon>
            );
        }

        if (process.transportModality === "Aéreo") {
            return (
                <S.Icon colorStep={colorIcon(isActive, process.returnOrigin)}>
                    <FaPlane />
                </S.Icon>
            );
        }

        if (process.transportModality === "Terrestre") {
            return (
                <S.Icon colorStep={colorIcon(isActive, process.returnOrigin)}>
                    <RiTruckFill />
                </S.Icon>
            );
        }

        return (
            <S.Icon colorStep={colorIcon(isActive, process.returnOrigin)}>
                <VscCircleFilled />
            </S.Icon>
        );
    };

    return (
        <>
            {process && (
                <>
                    <Collapse
                        openCollapse={openCollapse}
                        timeline
                        setOpenCollapse={setOpenCollapse}
                        title="Pré embarque"
                        colorTimeline={color(percent >= 10, process.returnOrigin)}
                        icon={
                            <S.Icon colorStep={colorIcon(percent >= 10, process.returnOrigin)}>
                                <FaCheck />
                            </S.Icon>
                        }
                    >
                        <div className="mb-4 pt-8">
                            <div className="flex items-center -ml-5">
                                <div className=" mr-4 bg-white p-1 ">
                                    <div
                                        className={`bg-${color(
                                            percent >= 10,
                                            process.returnOrigin
                                        )} h-2 w-2 ml-035 rounded-full`}
                                    ></div>
                                </div>
                                <p className="font-normal -ml-1">Dados de embarque</p>
                            </div>
                            <legend>previsão: {process.boardExpectedAtFormatted}</legend>
                            {process.boardedAtFormatted && <legend>efetiva: {process.boardedAtFormatted}</legend>}
                        </div>
                    </Collapse>
                    <Collapse
                        openCollapse={openCollapse}
                        setOpenCollapse={setOpenCollapse}
                        title="Em trânsito"
                        timeline
                        colorTimeline={color(percent >= 30, process.returnOrigin)}
                        icon={icon(process, percent >= 30)}
                    >
                        <div className="mb-4 pt-8">
                            <div className="flex items-center -ml-5">
                                <div className=" mr-4 bg-white p-1 ">
                                    <div
                                        className={`bg-${color(
                                            percent >= 30,
                                            process.returnOrigin
                                        )} h-2 w-2 ml-035 rounded-full`}
                                    ></div>
                                </div>
                                <p className="font-normal -ml-1">Dados de desembarque</p>
                            </div>
                            {process.arrivalExpectedAtFormatted && (
                                <legend>Previsão: {process.arrivalExpectedAtFormatted}</legend>
                            )}
                            {process.arrivedAtFormatted && <legend>Efetiva: {process.arrivedAtFormatted}</legend>}

                            {process.vessel && <legend>Navio: {process.vessel.name}</legend>}
                            {(process.vesselExtra1 || process.vesselExtra2) && (
                                <legend>
                                    Transbordo:{" "}
                                    <>
                                        {process.vesselExtra1.name}
                                        {process.vesselExtra2 && <>, {process.vesselExtra2.name}</>}
                                    </>
                                </legend>
                            )}
                        </div>
                    </Collapse>
                    <Collapse
                        openCollapse={openCollapse}
                        setOpenCollapse={setOpenCollapse}
                        title="Desembaraço"
                        timeline
                        colorTimeline={color(percent >= 50, process.returnOrigin)}
                        icon={
                            <S.Icon colorStep={colorIcon(percent >= 50, process.returnOrigin)}>
                                <AiTwotoneFile />
                            </S.Icon>
                        }
                    >
                        <div className="mb-4 pt-8">
                            <div className="flex items-center -ml-5">
                                <div className=" mr-4 bg-white p-1 ">
                                    <div
                                        className={`bg-${color(
                                            percent >= 50,
                                            process.returnOrigin
                                        )} h-2 w-2 ml-035 rounded-full`}
                                    ></div>
                                </div>
                                <p className="font-normal -ml-1">Dados de desembaraço</p>
                            </div>
                            {process.diNumber && <legend>Número da DI: {process.diNumber}</legend>}
                            {process.clearenceDiAtFormatted && <legend>Data da DI: {process.clearenceDiAtFormatted}</legend>}
                            {process.clearenceCiAtFormatted && <legend>Data da CI: {process.clearenceCiAtFormatted}</legend>}
                        </div>
                    </Collapse>
                    <Collapse
                        openCollapse={openCollapse}
                        setOpenCollapse={setOpenCollapse}
                        title="Faturamento"
                        timeline
                        colorTimeline={color(percent >= 70, process.returnOrigin)}
                        icon={
                            <S.Icon colorStep={colorIcon(percent >= 70, process.returnOrigin)}>
                                <FaDollarSign />
                            </S.Icon>
                        }
                    >
                        <div className="mb-4 pt-8">
                            <div className="flex items-center -ml-5">
                                <div className=" mr-4 bg-white p-1 ">
                                    <div
                                        className={`bg-${color(
                                            percent >= 70,
                                            process.returnOrigin
                                        )} h-2 w-2 ml-035 rounded-full`}
                                    ></div>
                                </div>
                                <p className="font-normal -ml-1">Dados de faturamento</p>
                            </div>
                            {process.billedAtFormatted && <legend>Faturado em: {process.billedAtFormatted}</legend>}
                        </div>
                    </Collapse>
                    <Collapse
                        openCollapse={openCollapse}
                        setOpenCollapse={setOpenCollapse}
                        title="Transporte"
                        timeline
                        colorTimeline={color(percent >= 90, process.returnOrigin)}
                        icon={
                            <S.Icon colorStep={colorIcon(percent >= 90, process.returnOrigin)}>
                                <RiTruckFill />
                            </S.Icon>
                        }
                    >
                        <div className="mb-4 pt-8">
                            <div className="flex items-center -ml-5">
                                <div className=" mr-4 bg-white p-1 ">
                                    <div
                                        className={`bg-${color(
                                            percent >= 90,
                                            process.returnOrigin
                                        )} h-2 w-2 ml-035 rounded-full`}
                                    ></div>
                                </div>
                                <p className="font-normal -ml-1">
                                    Dados de Transporte
                                </p>
                            </div>
                            {process.storedAtFormatted && <legend>Carregamento em: {process.storedAtFormatted}</legend>}
                            {process.freightToVendemmia?.cteNumber && (
                                <legend>CTE: {process.freightToVendemmia.cteNumber}</legend>
                            )}
                            {process.freightToVendemmia?.plates && (
                                <legend>Placa(s): {process.freightToVendemmia.plates}</legend>
                            )}
                            {process.freightToVendemmia?.recipientName && (
                                <legend>Destinatário: {process.freightToVendemmia.recipientName}</legend>
                            )}
                        </div>
                    </Collapse>
                    <Collapse
                        openCollapse={openCollapse}
                        setOpenCollapse={setOpenCollapse}
                        title="Entrega"
                        timeline
                        colorTimeline={color(percent >= 100, process.returnOrigin)}
                        icon={
                            <S.Icon colorStep={colorIcon(percent >= 100, process.returnOrigin)}>
                                <BsFlagFill />
                            </S.Icon>
                        }
                    >
                        <div className="mb-4 pt-8">
                            <div className="flex items-center -ml-5">
                                <div className=" mr-4 bg-white p-1 ">
                                    <div
                                        className={`bg-${color(
                                            percent >= 90,
                                            process.returnOrigin
                                        )} h-2 w-2 ml-035 rounded-full`}
                                    ></div>
                                </div>
                                <p className="font-normal -ml-1">Dados de entrega</p>
                            </div>
                            {process.deliveredAtFormatted && <legend>Entrega em: {process.deliveredAtFormatted}</legend>}
                        </div>
                    </Collapse>
                </>
            )}
        </>
    );
};
export default ProcessDetail;
