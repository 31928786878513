import styled from "styled-components";

export default styled.table`
    width: 100%;
    th.curto {
        width: 8%;
    }
    th.largo {
        width: 14%;
    }
`;
