import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { Popup } from "@urbica/react-map-gl";
import moment from "moment";

import Card from "../../../components/Cards";
import GraphMap from "../../../components/Graphs/GraphMap";
import Layout from "../../../components/Layout";
import Map from "../../../components/Map";
import Marked from "../../../components/Map/Marker";
import ProcessDetail from "../components/ProcessDetail";
import SEO from "../../../components/SEO";
import SubmenuDetail from "../components/SubmenuDetail";
import Tab from "../components/Tab";
import requests from "../../../services/requests";
import TableContainer from "./styles";

const ProcessesDetailPage = props => {
    const [openCollapse, setOpenCollapse] = useState("");
    const [openTab, setOpenTab] = useState("Atualizações");
    let [load, setLoad] = useState(false);
    let [process, setProcess] = useState({});
    const [openTabMap, setOpenTabMap] = useState("");
    const [onClicked, setOnClicked] = useState(false);
    const [urlTest, setUrlTest] = useState([]);
    const [viewport, setViewport] = useState({
        latitude: -14.235004,
        longitude: -51.925282,
        zoom: 2,
    });

    const centroid = coordinates => {
        let latXTotal = 0;
        let latYTotal = 0;
        let lonDegreesTotal = 0;

        if (coordinates.length === 0) {
            return {
                latitude: -14.235004,
                longitude: -51.925282,
            };
        }

        let currentLatLong;
        for (let i = 0; (currentLatLong = coordinates[i]); i++) {
            if (!currentLatLong.latitude || !currentLatLong.longitude) {
                continue;
            }
            const latDegrees = currentLatLong.latitude;
            const lonDegrees = currentLatLong.longitude;
            const latRadians = (Math.PI * latDegrees) / 180;
            latXTotal += Math.cos(latRadians);
            latYTotal += Math.sin(latRadians);

            lonDegreesTotal += lonDegrees;
        }
        const finalLatRadians = Math.atan2(latYTotal, latXTotal);
        const finalLatDegrees = (finalLatRadians * 180) / Math.PI;
        const finalLonDegrees = lonDegreesTotal / coordinates.length;

        return {
            latitude: finalLatDegrees,
            longitude: finalLonDegrees,
        };
    };

    const getVesselMmsi = process => {
        let mmsi = [];

        if (process.vessel && process.vessel.mmsi && process.vessel.mmsi !== "0") {
            mmsi.push(process.vessel);
        }
        if (process.vesselExtra1 && process.vesselExtra1.mmsi) {
            mmsi.push(process.vesselExtra1);
        }
        if (process.vesselExtra2 && process.vesselExtra2.mmsi) {
            mmsi.push(process.vesselExtra2);
        }

        return mmsi;
    };

    const getProcess = identifier => {
        setLoad(true);
        requests.showProcess(identifier).then(data => {
            setProcess({ ...data, vesselMmsi: getVesselMmsi(data) });

            const coordinates = [];
            for (let i in data?.freights) {
                if (data?.freights[i]?.lastLatitude && data?.freights[i]?.lastLongitude) {
                    coordinates.push({
                        latitude: data?.freights[i]?.lastLatitude,
                        longitude: data?.freights[i]?.lastLongitude,
                    });
                }
            }
            const centered = centroid(coordinates);

            setViewport({
                latitude: centered.latitude,
                longitude: centered.longitude,
                zoom: 10,
            });

            setLoad(false);
        });
    };

    // fix marineTraffic bug
    function fixSize() {
        const timer = setTimeout(() => {
            const iframe = document.getElementById("marinetraffic");
            if (iframe) {
                iframe.style.width = "100%";
                iframe.style.height = urlTest.length > 1 ? "350px" : "400px";
            }
        }, 4000);
        return () => clearTimeout(timer);
    }

    const getType = (modality, origin) => {
        let description;

        switch (origin?.toLowerCase()) {
            case "manual":
                description = "Própria";
                break;
            case "api":
                description = "Própria";
                break;
            case "gestao":
                description = "Gestão";
                break;
            default:
                description = modality;
        }

        return description;
    };

    useEffect(() => {
        setLoad(true);
        getProcess(props["*"]);
    }, []);

    useEffect(() => {
        let urls = [];
        process?.vesselMmsi?.length && process.vesselMmsi.map(vessel => {
            return (
                urls.push({
                    url: `https://www.marinetraffic.com/en/ais/embed/maptype:0/MMSI:${vessel.mmsi}/showmenu:false/remember:false`,
                    name: vessel.name
                })
            )
        });
        if (process?.vesselMmsi?.length > 0) {
            setOpenTabMap(process?.vesselMmsi[0]?.name);
        }
        setUrlTest(urls);
    }, [process]);

    return (
        <Layout>
            {load ? (
                <div className="flex flex-col items-center">
                    <ClipLoader size={20} loading={load} />
                </div>
            ) : (
                <>
                    {/*<Modal
                    onClose={actionModal}
                    show={openModal}
                    title={<h4>DETALHES DO PROCESSO</h4>}
                    size="xs:w-11/12 md:w-6/12 lg:w-4/12 min-h-80%"
                >
                    <ProcessDetail
                        openCollapse={openCollapseModal}
                        setOpenCollapse={setOpenCollapseModal}
                    />
                </Modal>*/}
                    <SEO title="Processos" />
                    <SubmenuDetail code={process.code} process={process} prevPath={props.location?.state?.prevPath} />
                    <div className="p-4 flex flex-wrap">
                        <div className=" w-full lg:w-2/5 lg:pr-2 xs:pr-0 lg:mb-0 xs:mb-4">
                            <Card id="card-bl-process">
                                <div className="flex justify-between mb-4">
                                    <p className="uppercase font-normal">Detalhes do processo</p>
                                    {/*<button
                            className="outline-none transform-none"
                            onClick={actionModal}
                          >
                            <Img
                              className="cursor-pointer"
                              fixed={fullscreen.childImageSharp.fixed}
                            />
                          </button>*/}
                                </div>
                                <ProcessDetail
                                    openCollapse={openCollapse}
                                    setOpenCollapse={setOpenCollapse}
                                    process={process}
                                />
                            </Card>
                        </div>
                        <div className="w-full lg:w-3/5 lg:pl-2 xs:pl-0">
                            <div
                                className="rounded-md border overflow-hidden border-C4 p-1"
                                style={{ height: 410, width: "100%" }}
                            >
                                {process?.showMapTransport && process?.freights?.length > 0 ? (
                                    <Map viewport={viewport} setViewport={setViewport}>
                                        {process?.freights &&
                                            process?.freights.map((entity, key) => {
                                                if (!entity || entity.isDelivered || !entity.lastLatitude || !entity.lastLongitude) {
                                                    return <></>;
                                                }
                                                return (
                                                    <div key={key}>
                                                        <Marked
                                                            key={key}
                                                            latitude={entity.lastLatitude}
                                                            longitude={entity.lastLongitude}
                                                            type="car"
                                                            color="roxo_oficial"
                                                            onClick={() => setOnClicked(!onClicked)}
                                                        />
                                                        {onClicked && (
                                                            <Popup
                                                                className="p-2"
                                                                latitude={entity.lastLatitude}
                                                                longitude={entity.lastLongitude}
                                                                closeButton={false}>
                                                                Placa: {entity.plates}
                                                                <br />
                                                                Data: {moment(entity.lastReceivedAt).format("DD/MM/YYYY")}
                                                                <br />
                                                                Hora: {moment(entity.lastReceivedAt).format("HH:mm")}
                                                                <br />
                                                            </Popup>
                                                        )}
                                                    </div>
                                                );
                                            })}
                                    </Map>
                                ) : (
                                    <>
                                        {(process.transportModality !== "Marítimo" ||
                                            (process.transportModality === "Marítimo" &&
                                                (process.currentStageModality !== "Trânsito" ||
                                                    process.arrivedAtFormatted !== undefined))) && (
                                                <GraphMap process={process} isProcessDetail={true} />
                                            )}

                                        <div className="tab-map">
                                            {process.transportModality === "Marítimo" &&
                                                process.currentStageModality === "Trânsito" &&
                                                process.arrivedAtFormatted === undefined && urlTest.map(e => (
                                                    <>
                                                        {urlTest.length > 1 && (
                                                            <span className={`tab-map-item ${openTabMap === e.name ? "active" : ""}`} onClick={() => setOpenTabMap(e.name)}>{e.name}</span>
                                                        )}
                                                        kaique
                                                        {openTabMap === e.name && (
                                                            <iframe
                                                                className="rounded-md"
                                                                title="Marine Traffic"
                                                                name="marinetraffic"
                                                                id="marinetraffic"
                                                                onLoad={() => fixSize()}
                                                                width={process.vesselMmsi ? "2000" : "100%"}
                                                                height={process.vesselMmsi ? "2000" : "350px"}
                                                                src={e.url}>
                                                                O navegador não suporta esse tipo de visualização, acesse diretamente: mar{" "}
                                                                <a href={e.url} target="_blank" rel="noreferrer">
                                                                    aqui
                                                                </a>
                                                            </iframe>
                                                        )}
                                                    </>
                                                ))}
                                        </div>
                                    </>
                                )}
                            </div>
                            <Card className="mt-4 w-full overflow-auto">
                                <h5>{process.code}</h5>
                                <TableContainer>
                                    <table className="table-auto mt-4 w-full text-left">
                                        <thead>
                                            <tr>
                                                <th className="largo">Início do processo</th>
                                                {/*<th>Status</th>*/}
                                                <th className="curto">Etapa</th>
                                                <th className="curto">Responsável</th>
                                                <th className="curto">Tipo</th>
                                                <th className="largo">Previsão de saída</th>
                                                <th className="largo">Previsão de chegada</th>
                                                <th className="largo">Chegada Efetiva</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{process.startedAtFormatted}</td>
                                                {/*<td>{process.status}</td>*/}
                                                <td>{process.currentStageModality}</td>
                                                <td>{process.userResponsibleConexos}</td>
                                                <td>{getType(process.modality, process.originDescription)}</td>
                                                <td>{process.boardExpectedAtFormatted}</td>
                                                <td>{process.arrivalExpectedAtFormatted}</td>
                                                <td>{process.arrivedAtFormatted}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </TableContainer>
                            </Card>
                        </div>
                    </div>
                    <Tab setOpenTab={setOpenTab} openTab={openTab} process={process} />
                </>
            )}
        </Layout>
    );
};

export default ProcessesDetailPage;