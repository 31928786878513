import styled from "styled-components";

export const Icon = styled.span`
    color: #ffffff;
    width: 1.5rem;
    height: 1.5rem;
    background-color: ${props => (props?.colorStep ? props?.colorStep : "#A7A7A7")};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
