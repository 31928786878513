import React from "react";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { useEffect } from "react";
import "./styles.css";

const Collapse = ({ title, children, openCollapse, setOpenCollapse, defaultActive, icon, timeline, colorTimeline }) => {
    const isOpen = description => {
        setOpenCollapse(description);
    };

    useEffect(() => {
        if (defaultActive) {
            setOpenCollapse(title);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let lineBorder;

    if (openCollapse === "") {
        lineBorder = "separator-collapse-off";
    } else if (openCollapse === "Em trânsito" || openCollapse === "Desembaraço") {
        lineBorder = "separator-collapse-on-exception";
    } else {
        lineBorder = "separator-collapse-on";
    }

    return (
        <div>
            <div
                className="bg-custom_c3 flex justify-between cursor-pointer rounded"
                onClick={() => isOpen(openCollapse === title ? "" : title)}
            >
                <div className="flex">
                    {icon && <div className="-ml-2 pr-2 my-1">{icon} </div>}
                    <h6>{title}</h6>
                </div>

                <div className="flex">
                    <div className="self-center">
                        {openCollapse === title ? (
                            <IoIosArrowUp className="text-roxo_oficial" />
                        ) : (
                            <IoIosArrowDown className="text-roxo_oficial" />
                        )}
                    </div>
                </div>
            </div>
            <div className={`${timeline ? `border-l-4 border-${colorTimeline} ${lineBorder}` : ""}`}>
                {title === openCollapse && <div className="pb-4 pl-4">{children}</div>}
            </div>
        </div>
    );
};
export default Collapse;
